import React from "react"

import SEO from "@components/seo"

const NotFoundPage = () => (
  <div className="container-fluid">
    <div className="py-9">
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </div>
)

export default NotFoundPage
